import { useState, useEffect } from 'react';
import Button, { ButtonVariant } from '../components/Button';
import Table, { Column } from '../components/Table';
import GlassModal from "../components/GlassModal";
import { authedGet, authedPost, yyyymmddToDate } from '../api';

import { poseidon_hash } from '../receipt_utils/poseidon';
import { unformat_balance_value_help, get_currency_proof_precision, get_currency_snapshot_precision } from '../receipt_utils/utils';
import { SNAPSHOT_PRECISION_DICT, PROOF_PRECISION_DICT } from '../receipt_utils/consts';
import { ec as EC } from 'elliptic';
const ec = new EC('secp256k1');

import { SHA256, enc } from 'crypto-js';

import { currencies } from '../api';
import { set } from 'date-fns';

interface AccountData {
  customer: string;
  bank: string;
  account: string;
  account_id: number;
  balance: number|null;
  currency: string;
  validated: boolean;
  validate: React.FC;
}

interface Banks {
  [key: string]: AccountData[];
}

interface Customers {
  [key: string]: Banks;
}

type LoadingError = string | null;

interface ValidateProps {
  private_key: string|null;
  account_id: number|null;
  validated: boolean;
  balance: number|null;
  index: number;
  setBalance: (index:number, newBal: number) => void;
}

interface Balances {
  [key: string]: number;
}
interface DisplayBalances {
  [key: string]: bigint;
}
interface StringBalances {
  [key:string]: string;
}
interface Attestation {
  sig_r: string;
  sig_s: string;
  pub_x: string;
  pub_y: string;
  attestor_id: number;
  proof_id: string;
  balances: StringBalances;
}

const Validate: React.FC<ValidateProps> = (props) => {
  const formatMoney = (value: string) => {
    if (value === '') {
      return ``
    };
    const cleanValue = value.replace(/[$,]/g, "");
    const formattedValue = Math.floor(parseFloat(cleanValue)).toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return `${formattedValue}`;
  };

  const [inputValue, setInputValue] = useState(formatMoney(props.balance === null ? "" : props.balance.toString()));
  const [variant, setVariant] = useState<ButtonVariant>(props.validated ? 'green' : 'default');
  const [label, setLabel] = useState(props.validated ? '✅ Validated' : 'Validate');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(formatMoney(e.target.value));
    const newBal = e.target.value.replace(/[$,]/g, "");
    props.setBalance(props.index, parseFloat(newBal));
  };

  const handleClick = () => {
    if (variant === 'default') {
      authedPost("/attestor/validate-balance", {
        account_id: props.account_id,
        balance: Math.floor(parseFloat(inputValue.replace(/[$,]/g, "")))
      })
      .then((res) => {
        if (res.data["success"]) {
          setVariant('green');
          setLabel('✅ Validated');
        } else {
          alert(res.data["message"]);
          setVariant('red');
          setLabel('Error');
        }
      })
    }
  }

  return (
    <div style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
      <input
        type="text"
        value={inputValue}
        onChange={handleChange}
        style={{ marginRight: '10px', fontSize: '16px', textAlign: 'right' }}
      />
      <div style={{ width: '20px'}}></div>
      { props.private_key !== null
        ? <></>
        : <Button text={label} onClick={handleClick} variant={variant} width={150}/>
      }
    </div>
  );
};

function AttestorBankData() {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<LoadingError>(null);
  const [privateKey, setPrivateKey] = useState<string>("");
  const [hasPrivateKey, setHasPrivateKey] = useState(false);
  const [signedBalances, setSignedBalances] = useState<Attestation|null>(null);
  const [missingModal, setMissingModal] = useState(false);
  const [multiAttestor, setMultiAttestor] = useState(false);

  const [data, setData] = useState<Customers>({});
  const [balances, setBalances] = useState<number[]>([]);

  const columns : Column[] = [
    { name: "account" , displayName: "Account"     , format: 'none'          , sortable: true },
    { name: "balance" , displayName: "Balance"     , format: 'nullable_money', sortable: true },
    { name: "validate", displayName: "Manual Entry", format: 'widget'        , sortable: false },
    { name: "currency", displayName: "Currency"    , format: 'none'          , sortable: true },
  ]

  let balHack: number[] = [];

  const setNewBalance = (i: number, newBal: number) => {
    balHack[i] = newBal;
    setBalances(balHack);
  }

  const fetchData = async (balanceHack : number[]) => {
    const user = JSON.parse(localStorage.getItem('user') ?? '');
    const res1 = await authedGet("/attestor/get-bank-balances");
    if (!res1.data["success"]) {
      setLoadingError(res1.data["message"]);
    } else {
      const newData: Customers = {};
      let i = 0;
      setBalances(balanceHack);
      for (const customer of Object.keys(res1.data.data)) {
        newData[customer] = {};
        for (const bank of Object.keys(res1.data.data[customer])) {
          newData[customer][bank] = res1.data.data[customer][bank].map((account: any) => {
            balHack.push(account.balance);
            i++;
            return ({ ...account,
                validate:
                  <Validate
                    private_key={user.has_private_key ? null : privateKey} // This seems backwards but it's not.
                                                                    // If the database has a private key,
                                                                    // we do the signing with an API call.
                                                                    // Else, we locally (above the table)
                                                                    // display the private key text box and
                                                                    // pass that to Validate to locally sign
                                                                    // the balance.
                    account_id={account.account_id}
                    validated={account.validated}
                    balance={account.balance}
                    index={i-1}
                    setBalance={setNewBalance}
                  />
              })
          }) as AccountData[];
        }
      };
      setMultiAttestor(user.is_default_multi);
      setData(newData);
    }
    setHasPrivateKey(user.has_private_key);
    setLoading(false);
  }

  useEffect(() => {
    fetchData([]);
  }, []);

  const handlePKChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrivateKey(e.target.value);
  };

  function formatDate(): string {
    const today = new Date();
    const year = today.getFullYear().toString();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');

    return year + month + day;
  }

  const validatePossiblyWithMissingData = () => {
    if (balances.length === 0) {
      alert("No balances to validate");
      return;
    };

    const user = JSON.parse(localStorage.getItem('user') ?? '');
    const proof_id = formatDate();

    // const testkey = '2e612b413044b9bbed7e62420933932cb01eda3fa54e7ebf32575c512ae2508f';
    if (privateKey.length < 1) {
      alert("Private key is invalid");
      return;
    }
    const key = ec.keyFromPrivate(privateKey, 'hex');
    //console.log(key.getPrivate().toString('hex'));
    const pub_x = key.getPublic().getX().toString('hex');
    const pub_y = key.getPublic().getY().toString('hex');

    let i = 0;
    const balance_map: Balances = {};
    const balance_map_display: DisplayBalances = {};
    for (const customer of Object.keys(data)) {
      for (const bank of Object.keys(data[customer])) {
        for (const account of data[customer][bank]) {
          // aggregate balances by currency
          if (balance_map[account.currency] === undefined) {
            balance_map[account.currency] = 0;
            balance_map_display[account.currency] = BigInt(0);
          }
          const thisBalance = balances[i] === null ? 0 : balances[i];
          balance_map[account.currency] += thisBalance;
          balance_map_display[account.currency] += BigInt(unformat_balance_value_help(thisBalance.toString(), get_currency_snapshot_precision(account.currency)));
          i++;
        }
      }
    }

    const string_display_balances: StringBalances = {};
    for (const currency of Object.keys(balance_map_display)) {
      string_display_balances[currency] = balance_map_display[currency].toString();
    }

    // map currencies to vector of balances
    const balance_vec = currencies.map((currency: string) => {
      if (balance_map[currency] === undefined) {
        return BigInt(0);
      } else {
        return unformat_balance_value_help(balance_map[currency].toString(), get_currency_proof_precision(currency));
      }
    });

    const hash_proof_id = (id: string): string => {
      const encoded = enc.Utf8.parse(id);
      const hash = SHA256(encoded);
      //console.log('hash: ', hash.toString());
      //console.log('hash_length: ', hash.toString().length);
      const chopped_hash = hash.toString().slice(0, 62);
      const bigIntValue = BigInt(`0x${chopped_hash}`);
      return bigIntValue.toString();
    }

    // take balances from balance_vec in groups of 16 (if prev_balance is null), poseidon_hash them
    // then iterate taking the next 15 balances and hashing them with the previous hash
    // until there is only one hash left

    const pad_array_with_zeros_at_the_end = (arr: bigint[]): bigint[] => {
      const new_arr = [...arr];
      while (new_arr.length < 16) {
        new_arr.push(BigInt(0));
      }
      return new_arr;
    }

    //console.log('balance_vec: ', balance_vec);
    let hashed_balance: bigint|null = null;
    i = 0;
    while (i < balance_vec.length) {
      let arr = []
      if (hashed_balance === null) {
        arr = balance_vec.slice(i, i+16);
        i += 16;
      } else {
        arr = pad_array_with_zeros_at_the_end([hashed_balance, ...balance_vec.slice(i, i+15)]);
        i += 15;
      }
      hashed_balance = poseidon_hash(arr);
    }

/*
    console.log('private key: ', privateKey);
    console.log('pub_x: ', pub_x);
    console.log('pub_y: ', pub_y);
    console.log('balances: ', string_display_balances);
    console.log('hashed_balance: ', hashed_balance);
*/
    const hashed_proof_id = hash_proof_id(proof_id);
    //console.log('proof_id: ', proof_id);
    //console.log('hashed_proof_id: ', hashed_proof_id);

    const use_account_id = multiAttestor ? 99998888 : user.attestor_id;
    const triplet: string[] = [use_account_id.toString(), hashed_proof_id, hashed_balance!.toString()];

    //console.log('triplet: ', triplet);

    const hash = poseidon_hash(triplet);

    console.log('hashed triplet: ', hash.toString());
    console.log('hashed triplet hex: ', hash.toString(16));

    const signature = key.sign(hash.toString(16));
    const newSignedBalance: Attestation = {
      pub_x: pub_x,
      pub_y: pub_y,
      sig_r: signature.r.toString('hex'),
      sig_s: signature.s.toString('hex'),
      attestor_id: user.attestor_id,
      proof_id: proof_id,
      balances: string_display_balances,
    };
    setSignedBalances(newSignedBalance);
  }

  const confirmMissingModal = () => {
    const yesGenerate = () => {
      validatePossiblyWithMissingData();
      setMissingModal(false);
    }
    return (
      <GlassModal isOpen={missingModal} onClose={() => setMissingModal(false)}>
        <h2>Missing Balances!</h2>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <p>Are you sure you want to generate the attestation?</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%', marginTop: "50px" }}>
            <Button text="Yes" onClick={yesGenerate}/>
            <Button text="No"  onClick={() => setMissingModal(false)}/>
          </div>
        </div>
      </GlassModal>
    );
  }

  const validateAll = () => {
    var all_there = true;
    for (const i in balances) {
      if (balances[i] === null) {
        all_there = false;
        setMissingModal(true);
        return;
      }
    };
    if (all_there) {
      validatePossiblyWithMissingData();
    }
  }

  return (
    <>
      { loading ? <div>Loading...</div> :
        (loadingError!==null) ? <div>Error: {loadingError}</div> :
        <>
          { confirmMissingModal() }
          { !hasPrivateKey ?
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '40px'}}>
              <h2>Private ECDSA Signing Key</h2>
              <input
                type="text"
                value={privateKey}
                onChange={handlePKChange}
                style={{ marginLeft: '20px', marginRight: '10px', fontSize: '16px', backgroundColor: '#eeeeff', border: '1px solid black'}}
              />
            </div>
            : <></>
          }
          { Object.keys(data).map((customer, i) =>
              <div key={customer} className="table_header">
                <div style={{marginRight: 'auto'}}>
                  <h2 style={{marginTop: 0, marginBottom: '4px'}}>
                      {customer}
                  </h2>
                  <div style={{marginLeft: '20px'}}>
                    { Object.keys(data[customer]).map((bank, j) =>
                        <div key={bank} className="table_header">
                          <div style={{marginRight: 'auto'}}>
                            <h3 style={{marginTop: '40px'}}>
                              {bank}
                            </h3>
                            <Table columns={columns} data={data[customer][bank]}/>
                          </div>
                        </div>
                    )}
                  </div>
                </div>
              </div>
          )}
          { !hasPrivateKey ?
            <div style={{ marginTop: '20px'}}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <h2>Is part of a multi-attestor attestation</h2>
                <input
                  type="checkbox"
                  style={{ marginLeft: '20px', width: '24px', height: '24px' }}
                  checked={multiAttestor}
                  onChange={(e) => setMultiAttestor(e.target.checked)}
                />
              </div>
              <Button text="Validate All" onClick={validateAll} width={150}/>
            </div>
            : <></>
          }
          { signedBalances !== null ?
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
              <h2>Signed Validations</h2>
              <Button text="Copy To Clipboard" onClick={() => navigator.clipboard.writeText(JSON.stringify(signedBalances, null, 2))} width={150}/>
              <textarea style={{width: "700px", overflowX: "scroll"}} value={JSON.stringify(signedBalances, null, 2)} readOnly/>
            </div>
            : <></>
          }
        </>
      }
    </>
  );
}

export default AttestorBankData;
