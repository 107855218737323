import { useState, useEffect } from 'react';
import Button, { ButtonVariant } from '../components/Button';
import Table, { Column } from '../components/Table';
import GlassModal from "../components/GlassModal";
import { authedGet, authedPost, yyyymmddToDate, formatDate, isAuthed } from '../api';

import styles from './Validator.module.css';

import { Validation, Acct_info } from './ValidationTypes';

interface Data {
  date: Date;
  customer: string;
  bank: string;
  account: string;
  balance: number;
  details: () => void;
};

interface Raw_acct_info {
  account_id: number;
  customer: string;
  bank: string;
  account: string;
}

type ValidationDetails = Validation | null;

function Validations() {
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(true);

  const [details, setDetails] = useState(false);
  const [validationDetails, setValidationDetails] = useState<ValidationDetails>(null);
  const [deleteBtn, setDeleteBtn] = useState<ButtonVariant>('alternative');

  const columns : Column[] = [
    { name: "date",     displayName: "Date",     format: 'date'  , sortable: true },
    { name: "customer", displayName: "Customer", format: 'none'  , sortable: true },
    { name: "bank",     displayName: "Bank",     format: 'none'  , sortable: true },
    { name: "account",  displayName: "Account",  format: 'none'  , sortable: true },
    { name: "balance",  displayName: "Balance",  format: 'money' , sortable: true },
    { name: "details",  displayName: "",         format: 'button', sortable: false },
  ]

  const fetchData = async () => {
    const res1 = await authedGet("/attestor/get-account-info");
    if (!res1.data["success"]) {
      alert(res1.data["message"]);
    } else {
      const acct_info: { [id: number]: Acct_info } = {};
      res1.data.account_info.forEach((acct: Raw_acct_info) => {
        acct_info[acct.account_id] = {
          customer: acct.customer,
          bank: acct.bank,
          account: acct.account,
        };
      });
      const res2 = await authedGet("/attestor/list-attestations");
      if (!res2.data["success"]) {
        alert(res2.data["message"]);
      } else {
        const data: Data[] = res2.data.attestations.map((validation: Validation) => {
          const acct_id = validation.account_id;
          return {
            date: yyyymmddToDate(validation.proof_id),
            customer: acct_info[acct_id].customer,
            bank: acct_info[acct_id].bank,
            account: acct_info[acct_id].account,
            balance: validation.balance,
            details: () => {
              setValidationDetails(validation);
              setDetails(true);
            }
          };
        });
        setData(data);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const detailsModal = () => {
    const handleDelete = () => {
      if (deleteBtn === 'alternative') {
        setDeleteBtn('red');
        return;
      } else {
        authedPost('/attestor/delete-attestation', {
          'attestation_id': validationDetails?.id,
        })
        .then((response) => {
          setDeleteBtn('alternative');
          setDetails(false);
          fetchData();
        })
        .catch((error) => {
          console.log(error);
        })
      }
    }
    return (
      <GlassModal isOpen={details} onClose={() => { setDeleteBtn('alternative'); setDetails(false) }}>
        <h2>Validation Details</h2>
        <div>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Created At</td>
              <td>
                {formatDate(validationDetails?.created_at)}
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>pub_x</td>
              <td>
                <input type="text" className={styles['input-box']} value={validationDetails?.pub_x} readOnly />
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>pub_y</td>
              <td>
                <input type="text" className={styles['input-box']} value={validationDetails?.pub_y} readOnly />
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>sig_r</td>
              <td>
                <input type="text" className={styles['input-box']} value={validationDetails?.sig_r} readOnly />
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>sig_s</td>
              <td>
                <input type="text" className={styles['input-box']} value={validationDetails?.sig_s} readOnly />
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        <Button onClick={handleDelete} text="Delete" width={-1} variant={deleteBtn}/>
        <div style={{height: 20}}/>
        <Button onClick={() => { setDeleteBtn('alternative'); setDetails(false) }} text="Close" width={-1}/>
      </GlassModal>
    );
  }

  return (
    <>
      <div className="table_header">
        <div style={{marginRight: 'auto'}}>
            <h2 style={{marginTop: 0, marginBottom: '4px'}}>
              All Validations
            </h2>
        </div>
      </div>
      {loading ? <div>Loading...</div> :
        <>
        <Table columns={columns} data={data}/>
        {detailsModal()}
        </>
      }
    </>
  );
}

export default Validations;
