import { useState, useEffect } from 'react';
import Button from '../components/Button';
import Table, { Column } from '../components/Table';
import GlassModal from "../components/GlassModal";
import { authedGet, authedPost } from '../api';

import styles from './Admin.module.css';

interface Data {
  id: number;
  name: string;
};

type LoadingError = string | null;

function Customers() {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<LoadingError>(null);

  const [data, setData] = useState<Data[]>([]);
  const [details, setDetails] = useState(false);
  const [newCustomer, setNewCustomer] = useState("");

  const columns : Column[] = [
    { name: "id"  , displayName: "Customer ID", format: 'none', sortable: true },
    { name: "name", displayName: "Name"       , format: 'none', sortable: true },
  ]

  const fetchData = async () => {
    const res1 = await authedGet("/admin/list-customers");
    if (!res1.data["success"]) {
      setLoadingError(res1.data["message"]);
    } else {
      setData(res1.data.customers);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const addModal = () => {
    const handleAdd = async () => {
      authedPost("/admin/add-customer", {customer_name: newCustomer})
        .then(res => {
          if (!res.data["success"]) {
            alert(res.data["msg"]);
          } else {
            setDetails(false);
            fetchData();
          }
        })
        .catch(err => {
          if (err.response) {
            console.log(err);
            alert(err.response.data["msg"]);
          }
        });
    };
    return (
      <GlassModal isOpen={details} onClose={() => setDetails(false)}>
        <h2>Add Customer</h2>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Customer Name</td>
              <td>
                <input
                  type="text"
                  className={styles['input-box']}
                  value={newCustomer}
                  onChange={(e: any) => setNewCustomer(e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{height: 20}}></div>
        <Button onClick={() => handleAdd()} text="Add To Database" width={-1}/>
      </GlassModal>
    );
  }

  return (
    <>
      { loading ? <div>Loading...</div> :
        (loadingError!==null) ? <div>Error: {loadingError}</div> :
        <>
          <div className="table_header">
            <div style={{marginRight: 'auto'}}>
                <h2 style={{marginTop: 0, marginBottom: '4px'}}>
                    Customers
                </h2>
            </div>
            <div style={{marginLeft: 'auto'}}>
                <Button onClick={() => setDetails(true)} text={'Add Customer'} variant='alternative' width={150}/>
            </div>
          </div>

          <Table columns={columns} data={data}/>
          {addModal()}
        </>
      }
    </>
  );
}

export default Customers;
