import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from './public/Home';
import Login from './public/Login';
import ViewProofs from './public/ViewProofs';

import Admin from './admin/Admin';
import Validator from './validator/Validator';
import Customer from './customer/Customer';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/admin" element={<Admin/>} />
      <Route path="/validator" element={<Validator/>} />
      <Route path="/customer" element={<Customer/>} />
      <Route path="/public/view-proofs" element={<ViewProofs/>} />
    </Routes>
  );
}

export default App;
