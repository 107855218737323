// GlassModal.tsx
import React from "react";
import styles from "./GlassModal.module.css";

interface GlassModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  glassModalClassName?: string;
  width?: string
}

const GlassModal: React.FC<GlassModalProps> = ({ isOpen, onClose, children, glassModalClassName, width }) => {
  if (!isOpen) {
    return null;
  }

  const modalStyle = {
    width: width || "auto"
  };

  return (
    <div className={`${styles.modalBackground}`} onClick={onClose}>
      <div
        className={`${styles.glassModal} ${glassModalClassName}`}
        style={modalStyle}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default GlassModal;
