const BITCOIN_TOKEN = "BTC";
const ETHER_TOKEN = "ETH";
const USD = "USD";
const JPY = "JPY";
const LTC="LTC";
const XRP="XRP";
const BCH="BCH";
const DOT="DOT";
const DOGE="DOGE";
const BAT="BAT";
const MKR="MKR";
const ENJ="ENJ";
const OMG="OMG";
const FTT="FTT";
const SOL="SOL";
const AVAX="AVAX";
export const SUPPORTED_TOKENS = [BITCOIN_TOKEN, ETHER_TOKEN, USD, JPY, LTC, XRP, BCH, DOT, DOGE, BAT, MKR, ENJ, OMG, FTT, SOL, AVAX];
export const PROOF_PRECISION_DICT = {
    BITCOIN_TOKEN: 8,
    ETHER_TOKEN: 7,
    USD: 2,
    JPY: 0,
    LTC: 5,
    XRP: 3,
    BCH: 6,
    DOT: 4,
    DOGE: 2,
    BAT: 3,
    MKR: 6,
    ENJ: 3,
    OMG: 3,
    FTT: 3,
    SOL: 5,
    AVAX: 5
};

const BALANCE_DIMENSION=18;
export const SNAPSHOT_PRECISION_DICT = {
    BITCOIN_TOKEN: 8,
    ETHER_TOKEN: 18,
    USD: 2,
    JPY: 0,
    LTC: 8,
    XRP: 6,
    BCH: 8,
    DOT: 10,
    DOGE: 8,
    BAT: 18,
    MKR: 18,
    ENJ: 18,
    OMG: 18,
    FTT: 18,
    SOL: 9,
    AVAX: 9
};