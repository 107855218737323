import TabbedContainer from '../components/TabbedContainer';
import Header from '../components/Header'

import Accounts from './Accounts';
import Validations from './Validations';
import Proofs from './Proofs';
import { isAuthed } from '../api';

import logoImage from '../images/proven-logo-red.png';

function Customer() {
  return (
    <>
    <Header pageName={'Customer Dashboard'} isAuthed={isAuthed} logoImage={logoImage}/>
      <TabbedContainer tabs={[
        { title: "Accounts"   , content: <Accounts/>   },
        { title: "Validations", content: <Validations/>},
        { title: "Proofs"     , content: <Proofs/>     },
      ]}/>
    </>
  );
}

export default Customer;
