import { useState, useEffect } from 'react';
import Button from '../components/Button';
import Table, { Column } from '../components/Table';
import GlassModal from "../components/GlassModal";
import { authedGet, yyyymmddToDate, formatDate } from '../api';

import styles from './Customer.module.css';

interface Validation {
  id: number;
  attestor_id : number;
  account_id: number;
  proof_id: number;
  balance: number;
  sig_r: string;
  sig_s: string;
  pub_x: string;
  pub_y: string;
  created_at: number;
  bank: string;
  account: string;
  attestor: string;
};

interface Data {
  bank: string;
  account: string;
  attestor: string;
  date: Date;
  balance: number;
  details: () => void;
}

type VldDetails = Validation | null;
type LoadingError = string | null;

function Validations() {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<LoadingError>(null);

  const [data, setData] = useState<Data[]>([]);
  const [details, setDetails] = useState(false);
  const [vldDetails, setVldDetails] = useState<VldDetails>(null);

  const columns : Column[] = [
    { name: "bank",     displayName: "Bank",     format: 'none'  , sortable: true },
    { name: "account",  displayName: "Account",  format: 'none'  , sortable: true },
    { name: "attestor", displayName: "Attestor", format: 'none'  , sortable: true },
    { name: "date",     displayName: "Date",     format: 'date'  , sortable: true },
    { name: "balance",  displayName: "Balance",  format: 'money' , sortable: true },
    { name: "details",  displayName: "",         format: 'button', sortable: false },
  ]

  const fetchData = async () => {
    const res1 = await authedGet("/customer/list-attestations");
    if (!res1.data["success"]) {
      setLoadingError(res1.data["message"]);
    } else {
      const data: Data[] = res1.data.attestations.map((vld: Validation) => {
        return {
          bank: vld.bank,
          account: vld.account,
          attestor: vld.attestor,
          date: yyyymmddToDate(vld.proof_id),
          balance: vld.balance,
          details: () => {
            setVldDetails(vld);
            setDetails(true);
          }
        };
      });
      setData(data);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const detailsModal = () => {
    return (
      <GlassModal isOpen={details} onClose={() => setDetails(false)}>
        <h2>Validation Details</h2>
        <div>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Created At</td>
              <td>
                {formatDate(vldDetails?.created_at)}
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>pub_x</td>
              <td>
                <input type="text" className={styles['input-box']} value={vldDetails?.pub_x} readOnly />
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>pub_y</td>
              <td>
                <input type="text" className={styles['input-box']} value={vldDetails?.pub_y} readOnly />
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>sig_r</td>
              <td>
                <input type="text" className={styles['input-box']} value={vldDetails?.sig_r} readOnly />
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>sig_s</td>
              <td>
                <input type="text" className={styles['input-box']} value={vldDetails?.sig_s} readOnly />
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        <Button onClick={() => setDetails(false)} text="Close" width={-1}/>
      </GlassModal>
    );
  }

  return (
    <>
      { loading ? <div>Loading...</div> :
        (loadingError!==null) ? <div>Error: {loadingError}</div> :
        <>
            <Table columns={columns} data={data}/>
            {detailsModal()}
        </>
      }
    </>
  );
}

export default Validations;
