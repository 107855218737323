import React, { useState } from "react";
import Button from "./Button";
import "./Table.css"

export type Format = "date" | "money" | "nullable_money" | "button" | "widget" | "colorTag" | "none" ;

export interface Column {
  name: string;
  displayName: string;
  format: Format;
  sortable: boolean;
}

interface TableProps {
  columns: Column[];
  data: Record<string, any>[];
}

const Table: React.FC<TableProps> = ({ columns, data }) => {
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);

  const sortData = (data: Record<string, any>[]) => {
    if (!sortColumn || !sortOrder) {
      return data;
    }

    return data.slice().sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];
      if (sortOrder === "asc") {
        return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      } else {
        return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
      }
    });
  };

  const toggleSort = (column: Column) => {
    if (!column.sortable) return;
    if (sortColumn === column.name) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column.name);
      setSortOrder("asc");
    }
  };

  const formatValue = (value: any, format: string) => {
    switch (format) {
      case "date":
        return new Date(value).toLocaleDateString();
      case "nullable_money":
        return value === null ? "unknown" : (
          <div style={{textAlign: "right"}}>
            { new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
             }).format(value)
            }
          </div>
        )
      case "money":
        return (
          <div style={{textAlign: "right"}}>
            { new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
             }).format(value)
            }
          </div>
        );
      case "button":
        return <Button text="Details" onClick={() => value()} />;
      case "colorTag":
        return (
        <div className={`colortag_container`}>
          <div className={`colortag`}>{value}</div>
        </div>
        )
      case "none":
        return (
          <div style={{textAlign: "left"}}>{value}</div>
        );
      case "widget":
        return value;
    }
  };

  return (
    <table className="table">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th
              key={index}
              onClick={() => toggleSort(column)}
              style={{ cursor: column.sortable ? "pointer" : "default" }}
            >
              {column.displayName}
              {column.sortable && (
                <span style={{ marginLeft: "0.25rem" }}>▼</span>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortData(data).map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((column, columnIndex) => (
              <td key={columnIndex}>
                <div style={{textAlign: "right", marginLeft: "10px", marginRight: "10px"}}>
                  {formatValue(row[column.name], column.format)}
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
