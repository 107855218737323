import axios, { AxiosResponse } from 'axios';

import { format } from "date-fns"; // for formatting timestamps

//export const server = "https://attest.proven.tools/api"
export const server = process.env.REACT_APP_ATTEST_API_URL;

export const isAuthed = () => {
  return localStorage.getItem("token") !== null
}

export const handleLogin = async (username: string, password: string) : Promise<string | null> => {
  try {
    const response = await axios.post(`${server}/public/login`, {
      'email': username,
      'password': password,
    });
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('user', JSON.stringify(response.data.user));
    return null
  } catch (error: AxiosResponse<any,any> | any) {
    return error.response.data.msg;
  }
};

export const authedPost = async (endpoint: string, contents: any) : Promise<AxiosResponse<any,any>> => {
  if (!isAuthed()) {
    throw new Error('Not logged in');
  };
  const token = localStorage.getItem('token');
  const ret = await axios.post(`${server}/${endpoint}`, contents, {
    baseURL: server,
    headers: {
      'authorization': `${token}`,
    },
  });
  return ret;
};

export const authedGet = async (endpoint: string) : Promise<AxiosResponse<any,any>> => {
  if (!isAuthed()) {
    throw new Error('Not logged in');
  };
  const token = localStorage.getItem('token');
  const ret = await axios.get(`${server}/${endpoint}`, {
    baseURL: server,
    headers: {
      'authorization': `${token}`,
    },
  });
  return ret;
};

export const yyyymmddToDate = (dInt: number) : Date => {
  const s = dInt.toString();
  const year: number = parseInt(s.slice(0, 4), 10);
  const month: number = parseInt(s.slice(4, 6), 10) - 1;
  const day: number = parseInt(s.slice(6, 8), 10);

  const date: Date = new Date(year, month, day);
  return date;
}

export const formatDate = (timestamp_in_seconds: number | undefined) => {
  if (timestamp_in_seconds === undefined) {
    return "";
  }
  const date = new Date(timestamp_in_seconds * 1000);
  return format(date, "yyyy-MM-dd hh:mm:ss")
}

export const dateToInt = (date: Date) : number => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const ret = year * 10000 + month * 100 + day;
  return ret;
}

export const currencies = [
  "BTC", "ETH", 'USD', 'JPY', 'LTC', 'XRP', 'BCH', 'DOT', 'DOGE', 'BAT', 'MKR', 'ENJ', 'OMG', 'FTT', 'SOL', 'AVAX', 'UNSUPPORTED_1', 'UNSUPPORTED_2'
]