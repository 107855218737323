import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Home.module.css';
import Button from '../components/Button';
import Input from '../components/Input';
import GlassModal from '../components/GlassModal';

import { isAuthed, handleLogin } from '../api';

import backgroundImage from '../images/office-desk.jpg';
import logoImage from '../images/proven-logo-red.png';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [badLogin, setBadLogin] = useState(false);

  const navigator = useNavigate();

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const doLogin = async () => {
    await handleLogin(username, password);
    if (isAuthed()) {
      const user_str = localStorage.getItem('user');
      if (user_str == null) {
        alert('User not found in local storage. Error!');
      } else {
        const user = JSON.parse(user_str);
        switch (user.kind) {
          case 'admin': navigator('/admin'); break;
          case 'attestor': navigator('/validator'); break;
          case 'customer': navigator('/customer'); break;
        }
      }
    } else {
      setBadLogin(true);
    }
  }

  return (
    <div className={styles.homeWrapper}>
      <div className={styles.leftSide}>
        <img src={logoImage} alt="Logo" className={styles.logo} />
        <div className={styles.title}>ZeKnow Validate</div>
        <div className={styles.subtitle}>Cryptographically Secure Validation</div>
        <div className={styles.buttonContainer}>
            <form onSubmit={(event) => {
              event.preventDefault();
              doLogin();
            }}>
              <Input placeholder="Email" value={username} onChange={handleUsernameChange} />
              <Input placeholder="Password" value={password} onChange={handlePasswordChange} isPassword />
            </form>
            <Button width={-1} text="Login" onClick={doLogin} />
        </div>
      </div>
      <div className={styles.rightSide}>
        <img src={backgroundImage} alt="Background" className={styles.backgroundImage} />
        <div className={styles.glassMorphismSquare}></div>
      </div>
      <GlassModal isOpen={badLogin} onClose={() => setBadLogin(false)}>
        <h2>Incorrect Email or Password</h2>
        <p>
          Please try again, or email contact@proven.tools for assistance.
        </p>
        <div className={styles.buttonSpacer} />
        <div className={styles.buttonSpacer} />
        <Button onClick={() => setBadLogin(false)} text="Close" width={150}/>
      </GlassModal>
    </div>
  );
};

export default Login;