import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import TabbedContainer from '../components/TabbedContainer';
import Table, { Column } from '../components/Table';
import GlassModal from '../components/GlassModal';
import Button, { ButtonVariant } from '../components/Button';
import axios from "axios";
import { isAuthed, server, yyyymmddToDate } from '../api';

import logoImage from '../images/proven-logo-red.png';

import "./ViewProofs.css";

type Data = {
  company: string;
  date: Date;
  total_balance: number;
  onchain_issuance: number;
  details: () => void;
};

type Validator = {
  name: string;
  url: string;
  pub_key_x: string;
  pub_key_y: string;
};

type Proof = {
  customer: string;
  timestamp : number;
  id: number
  total_balance: number;
  onchain_issuance: number;
  eth_blocknum: number;
  validators: Validator[];
  proof: string;
  pub_keys_x: string[];
  pub_keys_y: string[];
};

const PublicProofs: React.FC = () => {
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(true);
  const [proofDetails, setProofDetails] = useState<Proof | null>(null);
  const [details, setDetails] = useState(false);
  const [validator, setValidator] = useState<Validator | null>(null);
  const [valDetails, setValDetails] = useState(false);
  const [copyVariant, setCopyVariant] = useState<ButtonVariant>("default");
  const [verifyVariant, setverifyVariant] = useState<ButtonVariant>("default");
  const [verifyWords, setverifyWords] = useState("Verify Proof");

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const columns: Column[] = [
    { name: "company"         , displayName: "Company"          , format: 'none',   sortable: true },
    { name: "date"            , displayName: "Date"             , format: 'date',   sortable: true },
    { name: "total_balance"   , displayName: "Reserve Balance"  , format: 'money',  sortable: true },
    { name: "onchain_issuance", displayName: "On-Chain Issuance", format: 'money',  sortable: true },
    { name: "details"         , displayName: ""                 , format: 'button', sortable: false}
  ];

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`${server}/public/list-proofs`);
      console.log(result);
      if (!result.data["success"]) {
        alert(result.data["message"]);
      } else {
        const data: Data[] = result.data.proofs.map((proof: Proof) => {
          return {
            company: proof.customer,
            date: yyyymmddToDate(proof.id),
            total_balance: proof.total_balance,
            onchain_issuance: proof.onchain_issuance,
            details: () => {
              setProofDetails(proof);
              setDetails(true);
            }
          };
        });
        setData(data);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const copyToClipboard = () => {
    if (details !== null) {
      const text = JSON.stringify(details);
      navigator.clipboard.writeText(text);
      setCopyVariant("alternative");
    }
  };

  async function verifyProof(): Promise<void> {
    try {
      const response = await axios.post(`${server}/public/verify-proof`, proofDetails, {
        headers: { "Content-Type": "application/json" }
      });
      if (response.data.success) {
        setverifyVariant("green");
        setverifyWords("PROOF IS VALID")
      } else {
        setverifyVariant("red");
        setverifyWords("PROOF IS INVALID")
      }
    } catch (error) {
      console.error(error);
    }
  }

  function closeAll() {
    setDetails(false);
    setValDetails(false);
  }

  function closeVal() {
    setDetails(true);
    setValDetails(false);
  }

  return (
    <div>
      <Header pageName="Public Proofs" isAuthed={isAuthed} logoImage={logoImage} />
      <TabbedContainer
        tabs={[
          {
            title: 'Proofs',
            content: (
              <div>
                { loading ? <div>Loading...</div> :
                  <Table
                    columns={columns}
                    data={data}
                  />
                }
              </div>
            ),
          },
        ]}
      />
      <GlassModal isOpen={valDetails} onClose={closeVal}>
        <h2>Validator Details</h2>
        <div style={{width: "320px"}}>
          {(validator === null) ? <div></div> :
          <table>
            <tbody>
              <tr key="name">
                <td className="td-left">Name</td>
                <td className="td-right">{validator.name}</td>
              </tr>
              <tr key="url">
                <td className="td-left">URL</td>
                <td className="td-right"><a href={validator.url}>{validator.name} Keys</a></td>
              </tr>
              <tr key="pub_key_x">
                <td className="td-left">Public Key X</td>
                <td className="td-right">
                  <textarea className="textarea-mini" ref={textAreaRef} readOnly value={validator.pub_key_x} />
                </td>
              </tr>
              <tr key="pub_key_y">
                <td className="td-left">Public Key Y</td>
                <td className="td-right">
                  <textarea className="textarea-mini" ref={textAreaRef} readOnly value={validator.pub_key_y} />
                </td>
              </tr>
            </tbody>
          </table>
          }
        </div>
      </GlassModal>
      <GlassModal isOpen={details} onClose={closeAll}>
        <h2>Proof Details</h2>
        <div style={{width: "600px"}}>
          {(proofDetails === null) ? <div></div> :
          <table>
            <tbody>
              <tr key="customer">
                <td className="td-left">Customer</td>
                <td className="td-right">{proofDetails.customer}</td>
              </tr>
              <tr key="timestamp">
                <td className="td-left">Proof Timestamp</td>
                <td className="td-right">{(new Date(proofDetails.timestamp * 1000)).toLocaleString()}</td>
              </tr>
              <tr key="date">
                <td className="td-left">Validity Date</td>
                <td className="td-right">{yyyymmddToDate(proofDetails.id).toLocaleDateString()}</td>
              </tr>
              <tr key="total_balance">
                <td className="td-left">Reserve Balance</td>
                <td className="td-right">
                  { new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    }).format(proofDetails.total_balance)
                  }
                </td>
              </tr>
              <tr key="onchain_issuance">
                <td className="td-left">On-Chain Issuance</td>
                <td className="td-right">
                  { new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    }).format(proofDetails.onchain_issuance)
                  }
                </td>
              </tr>
              <tr key="eth_blocknum">
                <td className="td-left">Ethereum Block Number</td>
                <td className="td-right">{proofDetails.eth_blocknum}</td>
              </tr>
              <tr key="validators">
                <td className="td-left">Validators</td>
                <td className="td-right">
                  <table>
                    <tbody>
                      {proofDetails.validators.map((validator: Validator, idx2) => {
                        return (
                          <tr key={idx2}>
                            <td className="td-left">{validator.name}</td>
                            <td className="td-right">
                              <Button onClick={() => {
                                setValidator(validator);
                                setDetails(false);
                                setValDetails(true);
                              }} text="Info" width={100} variant='alternative'/>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr key="proof">
                <td className="td-left">Proof</td>
                <td className="td-right">
                  <textarea ref={textAreaRef} value={proofDetails.proof} readOnly={true} />
                </td>
              </tr>
            </tbody>
          </table>}
        </div>
        <div className='button-container'>
          <Button onClick={() => copyToClipboard()} text="Copy To Clipboard" width={240} variant={copyVariant}/>
          <Button onClick={async () => await verifyProof()} text={verifyWords} width={240} variant={verifyVariant}/>
        </div>
        <Button onClick={closeAll} text="Close" width={-1} variant='alternative'/>
      </GlassModal>
    </div>
  );
};

export default PublicProofs;
