import TabbedContainer from '../components/TabbedContainer';
import Header from '../components/Header'
import { isAuthed } from '../api';

import logoImage from '../images/proven-logo-red.png';

import AttestorBankData from './AttestorBankData';
import Validations from './Validations';

function Validator() {
  return (
    <>
    <Header pageName={'Validator Dashboard'} isAuthed={isAuthed} logoImage={logoImage}/>
      <TabbedContainer tabs={[
        {
          title: "Bank Balances",
          content:
            <AttestorBankData />
        },
        {
          title: "Validations",
          content:
            <Validations />
        }
      ]}/>
    </>
  );
}

export default Validator;
