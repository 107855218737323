import React, { useState } from 'react';
import styles from './TabbedContainer.module.css';

type Tab = {
  title: string;
  content: JSX.Element;
};

interface TabbedContainerProps {
  tabs: Tab[];
}

const TabbedContainer: React.FC<TabbedContainerProps> = ({ tabs }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (
    <div className={styles.container}>
      <div className={styles.tabList}>
        <div className={styles.innerContainer}>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`${styles.tabItem} ${index === selectedTabIndex ? styles.selected : ''}`}
              onClick={() => setSelectedTabIndex(index)}
            >
              {tab.title}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.contentContainer}>{tabs[selectedTabIndex].content}</div>
    </div>
  );
};

export default TabbedContainer;
