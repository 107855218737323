import { useState, useEffect } from 'react';
import Table, { Column } from '../components/Table';
import { authedGet } from '../api';

interface AccountData {
  customer: string;
  cust_id: number|null;
  bank: string;
  bank_id: number|null;
  account: string;
  account_id: number|null;
  balance: number;
}

interface Banks {
  [key: string]: AccountData[];
}

interface Customers {
  [key: string]: Banks;
}

type LoadingError = string | null;

function BankData() {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<LoadingError>(null);

  const [data, setData] = useState<Customers>({});

  const columns : Column[] = [
    { name: "cust_id"   , displayName: "Cust ID" , format: 'none', sortable: true },
    { name: "bank_id"   , displayName: "Bank ID" , format: 'none', sortable: true },
    { name: "account"   , displayName: "Account" , format: 'none', sortable: true },
    { name: "account_id", displayName: "Acct ID" , format: 'none', sortable: true},
    { name: "balance"   , displayName: "Balance" , format: 'money', sortable: true },
  ]

  const fetchData = async () => {
    const res1 = await authedGet("/admin/get-bank-balances");
    if (!res1.data["success"]) {
      setLoadingError(res1.data["message"]);
    } else {
      setData(res1.data.data as Customers);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      { loading ? <div>Loading...</div> :
        (loadingError!==null) ? <div>Error: {loadingError}</div> :
        <>
          { Object.keys(data).map((customer, i) =>
              <div key={customer} className="table_header">
                <div style={{marginRight: 'auto'}}>
                  <h2 style={{marginTop: 0, marginBottom: '4px'}}>
                      {customer}
                  </h2>
                  <div style={{marginLeft: '20px'}}>
                    { Object.keys(data[customer]).map((bank, j) =>
                        <div key={bank} className="table_header">
                          <div style={{marginRight: 'auto'}}>
                            <h3 style={{marginTop: '40px'}}>
                              {bank}
                            </h3>
                            <Table columns={columns} data={data[customer][bank]}/>
                          </div>
                        </div>
                    )}
                  </div>
                </div>
              </div>
          )}
        </>
      }
    </>
  );
}

export default BankData;
