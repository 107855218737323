import { useState, useEffect } from 'react';
import Button, { ButtonVariant } from '../components/Button';
import Table, { Column } from '../components/Table';
import GlassModal from "../components/GlassModal";
import { authedGet, authedPost, yyyymmddToDate } from '../api';

import CreateProof from './CreateProof';

import styles from './Admin.module.css';

interface Proof {
  id: number;
  customer_id : number;
  start_timestamp: number;
  finish_timestamp: number;
  status: string;
  total_balance: number;
  attestation_ids_csv: string;
  attestor_ids_csv: string;
  account_ids_csv: string;
  min_attestations_per_acct: number;
  proof: string;
  customer: string;
};

interface Data {
  customer : string;
  date: Date;
  status: string;
  balance: number;
  details: () => void;
}

type ProofDetails = Proof | null;
type LoadingError = string | null;

function Proofs() {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<LoadingError>(null);

  const [data, setData] = useState<Data[]>([]);
  const [details, setDetails] = useState(false);
  const [proofDetails, setProofDetails] = useState<ProofDetails>(null);

  const [deleteBtn, setDeleteBtn] = useState<ButtonVariant>('alternative');

  const [createProof, setCreateProof] = useState(false);

  const columns : Column[] = [
    { name: "customer", displayName: "Customer", format: 'none'  , sortable: true },
    { name: "date",     displayName: "Date",     format: 'date'  , sortable: true },
    { name: "status",   displayName: "Status",   format: 'none'  , sortable: true },
    { name: "balance",  displayName: "Balance",  format: 'money' , sortable: true },
    { name: "details",  displayName: "",         format: 'button', sortable: false },
  ]

  const fetchData = async () => {
    const res1 = await authedGet("/admin/list-proofs");
    if (!res1.data["success"]) {
      setLoadingError(res1.data["message"]);
    } else {
      console.log(res1.data.proofs);
      const data: Data[] = res1.data.proofs.map((proof: Proof) => {
        return {
          customer: proof.customer,
          date: yyyymmddToDate(proof.id),
          status: proof.status,
          balance: proof.total_balance,
          details: () => {
            setProofDetails(proof);
            setDetails(true);
          }
        };
      });
      setData(data);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!createProof) {
      fetchData();
    }
  }, [createProof]);

  const detailsModal = () => {
    const handleDelete = () => {
      if (deleteBtn === 'alternative') {
        setDeleteBtn('red');
        return;
      } else {
        authedPost('/admin/delete-proof', {
          'proof_id': proofDetails?.id,
          'customer_id': proofDetails?.customer_id,
        })
        .then((res) => {
          console.log(res);
          setDeleteBtn('alternative');
          setDetails(false);
          fetchData();
        })
        .catch(err => {
          if (err.response) {
            console.log(err);
            alert(err.response.data["msg"]);
          }
        });
      }
    }
    return (
      <GlassModal isOpen={details} onClose={() => { setDeleteBtn('alternative'); setDetails(false) }} width="500px">
        <h2>Proof Details</h2>
        <textarea className={styles.details} value={JSON.stringify(proofDetails, null, 2)} readOnly/>
        <div style={{height: 20}}></div>
        <Button onClick={handleDelete} text="Delete" width={-1} variant={deleteBtn}/>
        <div style={{height: 20}}/>
        <Button onClick={() => { setDeleteBtn('alternative'); setDetails(false) }} text="Close" width={-1}/>
      </GlassModal>
    );
  }

  return (
    <>
      { loading ? <div>Loading...</div> :
        (loadingError!==null) ? <div>Error: {loadingError}</div> :
        <>

          <div className="table_header">
            <div style={{marginRight: 'auto'}}>
                <h2 style={{marginTop: 0, marginBottom: '4px'}}>
                    All Proofs
                </h2>
            </div>
            <div style={{marginLeft: 'auto'}}>
                <Button onClick={() => setCreateProof(true)} text={'Create Proof'} variant='alternative' width={150}/>
            </div>
          </div>
          <Table columns={columns} data={data}/>
          <CreateProof isOpen={createProof} setIsOpen={setCreateProof}/>
          {detailsModal()}
        </>
      }
    </>
  );
}

export default Proofs;
