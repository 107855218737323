import { useState, useEffect } from 'react';
import Select from 'react-select';
import Button from '../components/Button';
import Table, { Column } from '../components/Table';
import GlassModal from "../components/GlassModal";
import { authedGet, authedPost } from '../api';

import styles from './Admin.module.css';
import { currencies } from '../api';

interface Account {
  id: number;
  customer_id: number;
  customer: string;
  bank_id: number;
  bank: string;
  currency: string;
  name: string;
}

interface Data {
  id: number;
  customer_id: number;
  customer: string;
  bank_id: number;
  bank: string;
  currency: string;
  name: string;
  remove: React.FC;
}

type LoadingError = string | null;

function Accounts() {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<LoadingError>(null);

  const [data, setData] = useState<Data[]>([]);
  const [details, setDetails] = useState(false);
  const [newCustomerID, setNewCustomerID] = useState("");
  const [newBankID, setNewBankID] = useState("");
  const [newAccountName, setNewAccountName] = useState("");
  const [newCurrency, setNewCurrency] = useState<{ label: string; value: number } | null>(null);

  const columns : Column[] = [
    { name: "id"         , displayName: "Acct ID" , format: 'none'  , sortable: true },
    { name: "customer_id", displayName: "Cust ID" , format: 'none'  , sortable: true },
    { name: "customer"   , displayName: "Customer", format: 'none'  , sortable: true },
    { name: "bank"       , displayName: "Bank"    , format: 'none'  , sortable: true },
    { name: "bank_id"    , displayName: "Bank ID" , format: 'none'  , sortable: true },
    { name: "currency"   , displayName: "Currency", format: 'none'  , sortable: true },
    { name: "name"       , displayName: "Name"    , format: 'none'  , sortable: true },
    { name: "remove"     , displayName: "Remove"  , format: 'widget', sortable: false}
  ]

  const currencyLut: { label: string; value: number }[] = [];
  currencies.forEach((currency: string, key: number) => {
    currencyLut.push({ label: currency, value: key });
  });

  const fetchData = async () => {
    const res1 = await authedGet("/admin/list-accounts");
    if (!res1.data["success"]) {
      setLoadingError(res1.data["msg"]);
    } else {
      const localData: Data[] = res1.data.accounts.map((account: Account) => {
        const execute = async () => {
          const body = {
            account_id: account.id,
          };
          authedPost("/admin/remove-account", body)
            .then(res => {
              if (!res.data["success"]) {
                alert(res.data["msg"]);
              } else {
                fetchData();
              }
            })
            .catch(err => {
              if (err.response) {
                console.log(err);
                alert(err.response.data["msg"]);
              }
            });
        };
        return { ...account,
          remove: <Button onClick={execute} text="Remove" variant="alternative" width={-1}/>,
        };
      });
      setData(localData);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const addModal = () => {
    const handleAdd = async () => {
      const body = {
        customer_id: newCustomerID,
        bank_id: newBankID,
        account_name: newAccountName,
        currency: newCurrency?.label,
      };
      authedPost("/admin/add-account", body)
        .then(res => {
          if (!res.data["success"]) {
            alert(res.data["msg"]);
          } else {
            setNewCustomerID("");
            setNewBankID("");
            setNewAccountName("");
            setDetails(false);
            fetchData();
          }
        })
        .catch(err => {
          if (err.response) {
            console.log(err);
            alert(err.response.data["msg"]);
          }
        });
      }

    return (
      <GlassModal isOpen={details} onClose={() => setDetails(false)}>
        <h2>Add Account</h2>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Customer ID</td>
              <td>
                <input
                  type="number"
                  className={styles['input-box']}
                  value={newCustomerID}
                  onChange={(e: any) => setNewCustomerID(e.target.value)}
                />
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Bank ID</td>
              <td>
                <input
                  type="number"
                  className={styles['input-box']}
                  value={newBankID}
                  onChange={(e: any) => setNewBankID(e.target.value)}
                />
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Account Name</td>
              <td>
                <input
                  type="text"
                  className={styles['input-box']}
                  value={newAccountName}
                  onChange={(e: any) => setNewAccountName(e.target.value)}
                />
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Currency</td>
              <td>
                <Select value={newCurrency} onChange={setNewCurrency} options={currencyLut} className={styles['input-box']}/>
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{height: 20}}></div>
        <Button onClick={() => handleAdd()} text="Add To Database" width={-1}/>
      </GlassModal>
    );
  }

  return (
    <>
      { loading ? <div>Loading...</div> :
        (loadingError!==null) ? <div>Error: {loadingError}</div> :
        <>
          <div className="table_header">
            <div style={{marginRight: 'auto'}}>
                <h2 style={{marginTop: 0, marginBottom: '4px'}}>
                    Accounts
                </h2>
            </div>
            <div style={{marginLeft: 'auto'}}>
                <Button onClick={() => setDetails(true)} text={'Add Account'} variant='alternative' width={150}/>
            </div>
          </div>

          <Table columns={columns} data={data}/>
          {addModal()}
        </>
      }
    </>
  );
}

export default Accounts;
