import React, { useState } from "react";

export type VldRowData = {
  id: number;
  bank: string;
  account: string;
  validator: string;
  balance: number;
};

type ValidationTableProps = {
  rows: VldRowData[];
  onSelectionChange: (selectedRows: VldRowData[]) => void;
};

const ValidationTable: React.FC<ValidationTableProps> = ({ rows, onSelectionChange }) => {
  const [selectedRowIds, setSelectedRowIds] = useState<Set<number>>(new Set());

  const toggleRowSelection = (rowId: number) => {
    const newSelectedRowIds = new Set(selectedRowIds);

    if (newSelectedRowIds.has(rowId)) {
      newSelectedRowIds.delete(rowId);
    } else {
      newSelectedRowIds.add(rowId);
    }

    setSelectedRowIds(newSelectedRowIds);
    const selectedRows = rows.filter((row) => newSelectedRowIds.has(row.id));
    onSelectionChange(selectedRows);
  };

  return (
    <table style={{width: "100%"}}>
      <thead style={{textAlign: "left"}}>
        <tr>
        <th>Selected</th>
        <th>ID</th>
        <th>Bank</th>
        <th>Account</th>
        <th>Validator</th>
        <th>Balance</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.id}>
            <td style={{textAlign: "center"}}>
              <input
                type="checkbox"
                checked={selectedRowIds.has(row.id)}
                onChange={() => toggleRowSelection(row.id)}
              />
            </td>
            <td>{row.id}</td>
            <td>{row.bank}</td>
            <td>{row.account}</td>
            <td>{row.validator}</td>
            <td>{row.balance}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ValidationTable;
