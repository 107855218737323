import { useState, useEffect } from 'react';
import Button from '../components/Button';
import Table, { Column } from '../components/Table';
import GlassModal from "../components/GlassModal";
import { authedGet, authedPost } from '../api';

import styles from './Admin.module.css';

interface Validator {
  id: number;
  name: string;
  private_key: string;
  website: string;
  is_default_multi: boolean
};

interface Data {
  id: number;
  name: string;
  private_key: string;
  website: string;
  is_default_multi: boolean;
  remove: React.FC;
};

type LoadingError = string | null;

function Validators() {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<LoadingError>(null);

  const [data, setData] = useState<Data[]>([]);
  const [details, setDetails] = useState(false);
  const [newValidator, setNewValidator] = useState("");
  const [newPrivateKey, setNewPrivateKey] = useState("");
  const [newWebsite, setNewWebsite] = useState("");

  const columns : Column[] = [
    { name: "id"              , displayName: "Validator ID" , format: 'none'  , sortable: true },
    { name: "name"            , displayName: "Name"         , format: 'none'  , sortable: true },
    { name: "is_default_multi", displayName: "Default Multi", format: 'none'  , sortable: true },
    { name: "remove"          , displayName: "Remove"       , format: 'widget', sortable: false}
  ]

  const fetchData = async () => {
    const res1 = await authedGet("/admin/list-attestors");
    if (!res1.data["success"]) {
      setLoadingError(res1.data["message"]);
    } else {
      const localData: Data[] = res1.data.attestors.map((validator: Validator) => {
        const execute = async () => {
          const body = {
            id: validator.id
          };
          authedPost("/admin/remove-attestor", body)
            .then(res => {
              if (!res.data["success"]) {
                alert(res.data["msg"]);
              } else {
                fetchData();
              }
            })
            .catch(err => {
              if (err.response) {
                console.log(err);
                alert(err.response.data["msg"]);
              }
            });
        };
        return {...validator,
          is_default_multi: validator.is_default_multi ? "Yes" : "No",
          remove: <Button onClick={execute} text="Remove" variant='alternative' width={-1}/>
        };
      });
      setData(localData);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const addModal = () => {
    const handleAdd = async () => {
      authedPost("/admin/add-attestor", {attestor_name: newValidator, private_key: newPrivateKey, website: newWebsite})
        .then(res => {
          if (!res.data["success"]) {
            alert(res.data["msg"]);
          } else {
            setDetails(false);
            fetchData();
          }
        })
        .catch(err => {
          if (err.response) {
            console.log(err);
            alert(err.response.data["msg"]);
          }
        });
    };
    return (
      <GlassModal isOpen={details} onClose={() => setDetails(false)}>
        <h2>Add Validator</h2>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Validator Name</td>
              <td>
                <input
                  type="text"
                  className={styles['input-box']}
                  value={newValidator}
                  onChange={(e: any) => setNewValidator(e.target.value)}
                />
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Private Key</td>
              <td>
                <input
                  type="text"
                  className={styles['input-box']}
                  value={newPrivateKey}
                  onChange={(e: any) => setNewPrivateKey(e.target.value)}
                />
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Website</td>
              <td>
                <input
                  type="text"
                  className={styles['input-box']}
                  value={newWebsite}
                  onChange={(e: any) => setNewWebsite(e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{height: 20}}></div>
        <Button onClick={() => handleAdd()} text="Add To Database" width={-1}/>
      </GlassModal>
    );
  }

  return (
    <>
      { loading ? <div>Loading...</div> :
        (loadingError!==null) ? <div>Error: {loadingError}</div> :
        <>
          <div className="table_header">
            <div style={{marginRight: 'auto'}}>
                <h2 style={{marginTop: 0, marginBottom: '4px'}}>
                    Validators
                </h2>
            </div>
            <div style={{marginLeft: 'auto'}}>
                <Button onClick={() => setDetails(true)} text={'Add Validator'} variant='alternative' width={150}/>
            </div>
          </div>

          <Table columns={columns} data={data}/>
          {addModal()}
        </>
      }
    </>
  );
}

export default Validators;
