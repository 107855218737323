import { useState, useEffect } from 'react';
import Button from '../components/Button';
import Table, { Column } from '../components/Table';
import GlassModal from "../components/GlassModal";
import { authedGet, yyyymmddToDate } from '../api';

import styles from './Customer.module.css';

interface Proof {
  id: number;
  customer_id : number;
  start_timestamp: number;
  finish_timestamp: number;
  status: string;
  total_balance: number;
  attestation_ids_csv: string;
  attestor_ids_csv: string;
  account_ids_csv: string;
  min_attestations_per_acct: number;
  proof: string;
};

interface Data {
  date: Date;
  status: string;
  balance: number;
  details: () => void;
}

type ProofDetails = Proof | null;
type LoadingError = string | null;

function Proofs() {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<LoadingError>(null);

  const [data, setData] = useState<Data[]>([]);
  const [details, setDetails] = useState(false);
  const [proofDetails, setProofDetails] = useState<ProofDetails>(null);

  const columns : Column[] = [
    { name: "date",     displayName: "Date",     format: 'date'  , sortable: true },
    { name: "status",   displayName: "Status",   format: 'none'  , sortable: true },
    { name: "balance",  displayName: "Balance",  format: 'money' , sortable: true },
    { name: "details",  displayName: "",         format: 'button', sortable: false },
  ]

  const fetchData = async () => {
    const res1 = await authedGet("/customer/list-proofs");
    if (!res1.data["success"]) {
      setLoadingError(res1.data["message"]);
    } else {
      console.log(res1.data.proofs);
      const data: Data[] = res1.data.proofs.map((proof: Proof) => {
        return {
          date: yyyymmddToDate(proof.id),
          status: proof.status,
          balance: proof.total_balance,
          details: () => {
            setProofDetails(proof);
            setDetails(true);
          }
        };
      });
      setData(data);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const detailsModal = () => {
    return (
      <GlassModal isOpen={details} onClose={() => setDetails(false)} width="500px">
        <h2>Proof Details</h2>
        <textarea className={styles.details} value={JSON.stringify(proofDetails, null, 2)} readOnly/>
        <div style={{height: 20}}></div>
        <Button onClick={() => setDetails(false)} text="Close" width={-1}/>
      </GlassModal>
    );
  }

  return (
    <>
      { loading ? <div>Loading...</div> :
        (loadingError!==null) ? <div>Error: {loadingError}</div> :
        <>
            <Table columns={columns} data={data}/>
            {detailsModal()}
        </>
      }
    </>
  );
}

export default Proofs;
