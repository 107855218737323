import { useState, useEffect } from 'react';
import Button from '../components/Button';
import Table, { Column } from '../components/Table';
import GlassModal from "../components/GlassModal";
import { authedGet, authedPost } from '../api';

import styles from './Admin.module.css';

interface Bank {
  id: number;
  name: string;
};

interface Data {
  id: number;
  name: string;
  remove: React.FC;
};

type LoadingError = string | null;

function Banks() {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<LoadingError>(null);

  const [data, setData] = useState<Data[]>([]);
  const [details, setDetails] = useState(false);
  const [newBank, setNewBank] = useState("");
  const [newBankInternal, setNewBankInternal] = useState("");

  const columns : Column[] = [
    { name: "id"    , displayName: "Bank ID", format: 'none'  , sortable: true },
    { name: "name"  , displayName: "Name"   , format: 'none'  , sortable: true },
    { name: "remove", displayName: "Remove" , format: 'widget', sortable: false}
  ]

  const fetchData = async () => {
    const res1 = await authedGet("/admin/list-banks");
    if (!res1.data["success"]) {
      setLoadingError(res1.data["message"]);
    } else {
      const localData: Data[] = res1.data.banks.map((bank: Bank) => {
        const execute = async () => {
          const body = {
            id: bank.id
          };
          const res2 = await authedPost("/admin/remove-bank", body);
          if (!res2.data["success"]) {
            alert(res2.data["msg"]);
          } else {
            fetchData();
          }
        };
        return {...bank,
          remove: <Button onClick={execute} text="Remove" variant='alternative'/>
        };
      });
      setData(localData);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const addModal = () => {
    const handleAdd = async () => {
      authedPost("/admin/add-bank", {bank_name: newBank, internal_name: newBankInternal})
        .then(res => {
          if (!res.data["success"]) {
            alert(res.data["msg"]);
          } else {
            setNewBank("");
            setNewBankInternal("");
            setDetails(false);
            fetchData();
          }
        })
        .catch(err => {
          if (err.response) {
            console.log(err);
            alert(err.response.data["msg"]);
          }
        });
    };
    return (
      <GlassModal isOpen={details} onClose={() => setDetails(false)}>
        <h2>Add Bank</h2>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Bank Name</td>
              <td>
                <input
                  type="text"
                  className={styles['input-box']}
                  value={newBank}
                  onChange={(e: any) => setNewBank(e.target.value)}
                />
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Bank Internal Name</td>
              <td>
                <input
                  type="text"
                  className={styles['input-box']}
                  value={newBankInternal}
                  onChange={(e: any) => setNewBankInternal(e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{height: 20}}></div>
        <Button onClick={() => handleAdd()} text="Add To Database" width={-1}/>
      </GlassModal>
    );
  }

  return (
    <>
      { loading ? <div>Loading...</div> :
        (loadingError!==null) ? <div>Error: {loadingError}</div> :
        <>
          <div className="table_header">
            <div style={{marginRight: 'auto'}}>
                <h2 style={{marginTop: 0, marginBottom: '4px'}}>
                    Banks
                </h2>
            </div>
            <div style={{marginLeft: 'auto'}}>
                <Button onClick={() => setDetails(true)} text={'Add Bank'} variant='alternative'/>
            </div>
          </div>

          <Table columns={columns} data={data}/>
          {addModal()}
        </>
      }
    </>
  );
}

export default Banks;
