import { get_mds_matrix, get_rc, get_RP } from "./poseidon_consts.js";

// Prime for BN254 ZK circuits
const p = BigInt("0x30644e72e131a029b85045b68181585d2833e84879b9709143e1f593f0000001");

// REQUIRES: x is a BigInt in [0, p)
// ENSURES: Result is x^5 mod p as a BigInt
function s_box(x) {
    var a = (x * x) % p;
    var b = (a * a) % p;
    return (x * b) % p;
}

// REQUIRES: a,b are equal-length arrays of BigInts in [0, p)
// ENSURES: Result is the dotproduct in Zp as a BigInt
function dotprod(a, b) {
    if (a.length !== b.length) {
        throw new Error("error in dotprod");
    }
    var res = BigInt(0);
    var temp1;
    var temp2;
    for (var i = 0; i < a.length; i++) {
        temp1 = (a[i] * b[i]);
        temp2 = temp1 % p;
        res += temp2;
    }
    return res % p;
}


// REQUIRES: M is nxn matrix of BigInts (in Zp)
// REQUIRES: x is an n-dimensional vector of BigInts (in Zp)
function matrix_multiply(M, x) {
    if (M.length !== M[0].length) {
        throw new Error("MDS Matrix is not square");
    }
    if (M.length !== x.length) {
        throw new Error("M and x have mismatched dimensions");
    }
    var b = [];
    for (var i = 0; i < x.length; i++) {
        b.push(dotprod(M[i], x));
    }
    return b;
}

// REQUIRES: State is an array of BigInts (in Zp)
function perm(state){
    var t = state.length;
    var m = get_mds_matrix(t); // all bigints
    var rc = get_rc(t); // all strings
    var Rf = 4;
    var RP = get_RP(t);

    var rc_counter = 0;
    // First full rounds
    for (let i = 0; i < Rf; i++) {
        // Round constants, nonlinear layer, matrix mult
        for (let j = 0; j < t; j++) {
            state[j] = (state[j] + BigInt(rc[rc_counter])) % p;
            rc_counter += 1;
        }
        for (let j = 0; j < t; j++) {
            state[j] = s_box(state[j]);
        }
        state = matrix_multiply(m, state);
    }
    // Middle partial rounds
    for (let i = 0; i < RP; i++) {
        // Round constants, nonlinear layer, matrix mult
        for (let j = 0; j < t; j++) {
            state[j] = (state[j] + BigInt(rc[rc_counter])) % p;
            rc_counter += 1;
        }
        state[0] = s_box(state[0]);
        state = matrix_multiply(m, state);
    }
    // Last full rounds
    for (let i = 0; i < Rf; i++) {
        // Round constants, nonlinear layer, matrix mult
        for (let j = 0; j < t; j++) {
            state[j] = (state[j] + BigInt(rc[rc_counter])) % p;
            rc_counter += 1;
        }
        for (let j = 0; j < t; j++) {
            state[j] = s_box(state[j]);
        }
        state = matrix_multiply(m, state);
    }
    return state;
}

// REQUIRES: Input is an array of string-represented numbers
// ENSURES: Returns a BigInt in Zp
export function poseidon_hash(input) {
    var state = [BigInt(0)];
    for (var i = 0; i < input.length; i++) {
        state.push(BigInt(input[i]));
    }

    return perm(state)[0];
}
