import React from 'react';
import './Button.css';

export type ButtonVariant = "default" | "alternative" | "green" | "red" | "disabled";

interface ButtonProps {
  width?: number;
  height?: number;
  variant?: ButtonVariant;
  text?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({ width = 94, height = 42, variant = 'default', text = 'Login', onClick, className, style }) => {
  const buttonStyle = {
    width: (width === -1) ? `100%` : `${width}px`,
    height: `${height}px`,
    disabled: variant === 'disabled',
    ...style
  };

  return (
    <button className={`button ${variant} ${className}`} style={buttonStyle} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;
