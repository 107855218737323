import { useState, useEffect } from 'react';
import Button from '../components/Button';
import Select from 'react-select';
import Table, { Column } from '../components/Table';
import GlassModal from "../components/GlassModal";
import { authedGet, authedPost } from '../api';

import styles from './Admin.module.css';

interface Data {
  id: number;
  username: string;
  email: string;
  kind: string;
  group_id: number;
};

type LoadingError = string | null;

function Users() {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<LoadingError>(null);

  const [data, setData] = useState<Data[]>([]);
  const [details, setDetails] = useState(false);

  const [newUsername, setNewUsername] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newKind, setRawNewKind] = useState<{ label: string; value: string } | null>(null);
  const [newGroupID, setNewGroupID] = useState("");

  const columns : Column[] = [
    { name: "id"      , displayName: "ID"      , format: 'none', sortable: true },
    { name: "username", displayName: "Username", format: 'none', sortable: true },
    { name: "email"   , displayName: "Email"   , format: 'none', sortable: true },
    { name: "kind"    , displayName: "Kind"    , format: 'none', sortable: true },
    { name: "group_id", displayName: "Group ID", format: 'none', sortable: true },
  ]

  const fetchData = async () => {
    const res1 = await authedGet("/admin/list-users");
    if (!res1.data["success"]) {
      setLoadingError(res1.data["message"]);
    } else {
      setData(res1.data.users);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const addModal = () => {
    const handleAdd = async () => {
      const body = {
        username: newUsername,
        email: newEmail,
        password: newPassword,
        kind: newKind?.value as string,
        group_id: Number(newGroupID)
      };
      authedPost("/admin/add-user", body)
        .then(res => {
          if (!res.data["success"]) {
            alert(res.data["msg"]);
          } else {
            setDetails(false);
            fetchData();
          }
        })
        .catch(err => {
          if (err.response) {
            console.log(err);
            alert(err.response.data["msg"]);
          }
        });
    };

    const setNewKind = (newKind: { label: string; value: string } | null) => {
      setRawNewKind(newKind);
    };

    const kindOptions = [
      { label: "Admin"    , value: "admin"    },
      { label: "Customer" , value: "customer" },
      { label: "Validator", value: "attestor" }
    ];

    return (
      <GlassModal isOpen={details} onClose={() => setDetails(false)}>
        <h2>Add User</h2>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Username</td>
              <td>
                <input
                  type="text"
                  className={styles['input-box']}
                  value={newUsername}
                  onChange={(e: any) => setNewUsername(e.target.value)}
                />
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Email</td>
              <td>
                <input
                  type="text"
                  className={styles['input-box']}
                  value={newEmail}
                  onChange={(e: any) => setNewEmail(e.target.value)}
                />
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Password</td>
              <td>
                <input
                  type="text"
                  className={styles['input-box']}
                  value={newPassword}
                  onChange={(e: any) => setNewPassword(e.target.value)}
                />
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Kind</td>
              <td>
                <Select value={newKind} onChange={setNewKind} options={kindOptions} className={styles['input-box']}/>
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Group ID</td>
              <td>
                <input
                  type="text"
                  className={styles['input-box']}
                  value={newGroupID}
                  onChange={(e: any) => setNewGroupID(e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{height: 20}}></div>
        <Button onClick={() => handleAdd()} text="Add To Database" width={-1}/>
      </GlassModal>
    );
  }

  return (
    <>
      { loading ? <div>Loading...</div> :
        (loadingError!==null) ? <div>Error: {loadingError}</div> :
        <>
          <div className="table_header">
            <div style={{marginRight: 'auto'}}>
                <h2 style={{marginTop: 0, marginBottom: '4px'}}>
                    All Users
                </h2>
            </div>
            <div style={{marginLeft: 'auto'}}>
                <Button onClick={() => setDetails(true)} text={'Add User'} variant='alternative' width={150}/>
            </div>
          </div>

          <Table columns={columns} data={data}/>
          {addModal()}
        </>
      }
    </>
  );
}

export default Users;
