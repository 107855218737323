import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Home.module.css';
import Button, { ButtonVariant } from '../components/Button';
import GlassModal from '../components/GlassModal';

import { server } from "../api";
import axios from "axios";

import backgroundImage from '../images/office-desk.jpg';
import logoImage from '../images/proven-logo-red.png';

const Home: React.FC = () => {
  const navigator = useNavigate();

  const [proofDetails, setProofDetails] = useState("");
  const [details, setDetails] = useState(false);
  const [verifyVariant, setverifyVariant] = useState<ButtonVariant>("default");
  const [verifyWords, setverifyWords] = useState("Verify Proof");

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setProofDetails(event.target.value);
  };

  async function verifyProof(): Promise<void> {
    try {
      const response = await axios.post(`${server}/public/verify-proof`, JSON.parse(proofDetails), {
        headers: { "Content-Type": "application/json" }
      });
      if (response.data.success) {
        setverifyVariant("green");
        setverifyWords("PROOF IS VALID")
      } else {
        setverifyVariant("red");
        setverifyWords("PROOF IS INVALID")
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={styles.homeWrapper}>
      <div className={styles.leftSide}>
        <img src={logoImage} alt="Logo" className={styles.logo} />
        <div className={styles.title}>ZeKnow Validate</div>
        <div className={styles.subtitle}>Cryptographically Secure Validation</div>
        <div className={styles.buttonContainer}>
            <Button width={-1} text="View Proofs" onClick={() => navigator('/public/view-proofs')}/>
            <div className={styles.buttonSpacer}></div>
            <Button width={-1} text="Verify Proof" onClick={() => setDetails(true)} variant='alternative'/>
        </div>
      </div>
      <div className={styles.rightSide}>
        <img src={backgroundImage} alt="Background" className={styles.backgroundImage} />
        <div className={styles.loginContainer}>
            <Button text="Login" variant='alternative' onClick={() => navigator('/login')}/>
        </div>
        <div className={styles.glassMorphismSquare}></div>
      </div>
      <GlassModal isOpen={details} onClose={() => setDetails(false)}>
        <h2>Proof Details</h2>
        <textarea value={proofDetails} onChange={handleChange}></textarea>
        <div className='button-container'>
          <Button onClick={async () => await verifyProof()} text={verifyWords} width={500} variant={verifyVariant}/>
        </div>
        <Button onClick={() => setDetails(false)} text="Close" width={-1} variant='alternative'/>
      </GlassModal>
    </div>

  );
};

export default Home;