import {SUPPORTED_TOKENS, PROOF_PRECISION_DICT, SNAPSHOT_PRECISION_DICT} from './consts.js';
export function unformat_balance_value_help(balance_str, precision) {
    var decimal_idx = balance_str.indexOf('.');
    if (decimal_idx === -1) {
        var truncated = balance_str;
        var have_dropped_digits = false;
        var n_digits_after_decimal = 0;
    } else {
        var truncated = balance_str.slice(0, decimal_idx + 1 + precision);
        var remainder = balance_str.slice(decimal_idx + 1 + precision);
        var have_dropped_digits = (remainder.length > 0) && (Number(remainder) > 0)
        var n_digits_after_decimal = truncated.length - decimal_idx - 1;
    }
    var n_remaining_precision_digits = precision - n_digits_after_decimal;

    var bi_balance = BigInt(Number(truncated) * (10**n_digits_after_decimal));
    var bi_mult = BigInt(10**(n_remaining_precision_digits));
    var unformatted_balance = bi_balance * bi_mult;

    if (have_dropped_digits) {
        unformatted_balance += BigInt(1);
    }
    return unformatted_balance;
}

export function get_balance_orders(balance_dimension) {
    var balance_order_len = SUPPORTED_TOKENS.length;
    if (balance_dimension < balance_order_len) {
        balance_order_len = balance_dimension;
    }
    var balance_orders = [];
    for (let i = 0; i < balance_order_len; i++) {
        balance_orders.push(SUPPORTED_TOKENS[i]);
    }
    for (let i = balance_order_len; i < balance_dimension; i++) {
        balance_orders.push("Unsupported-Index-" + i);
    }
    return balance_orders;
}

export function get_currency_proof_precision(currency) {
    var token_type=null;
    for (var i = 0; i < SUPPORTED_TOKENS.length; i++) {
        if(currency === SUPPORTED_TOKENS[i]) {
            token_type = SUPPORTED_TOKENS[i];
        }
    }
    if (token_type === null) {
        throw new Error("Currenty type " + currency + " didn't match any supported tokens");
    }
    return PROOF_PRECISION_DICT[token_type];
}

export function get_currency_snapshot_precision(currency) {
    var token_type=null;
    for (var i = 0; i < SUPPORTED_TOKENS.length; i++) {
        if(currency === SUPPORTED_TOKENS[i]) {
            token_type = SUPPORTED_TOKENS[i];
        }
    }
    if (token_type === null) {
        throw new Error("Currenty type " + currency + " didn't match any supported tokens");
    }
    return SNAPSHOT_PRECISION_DICT[token_type];
}