import { useState, useEffect } from 'react';
import Button from '../components/Button';
import Table, { Column } from '../components/Table';
import GlassModal from "../components/GlassModal";
import { authedGet } from '../api';

interface Raw {
  bank: string;
  account: string;
  attestors: string[];
}

interface Data {
  bank: string;
  account: string;
  details: () => void;
}

type AcctDetails = Raw | null;
type LoadingError = string | null;

function Accounts() {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<LoadingError>(null);

  const [data, setData] = useState<Data[]>([]);
  const [details, setDetails] = useState(false);
  const [acctDetails, setAcctDetails] = useState<AcctDetails>(null);

  const columns : Column[] = [
    { name: "bank",     displayName: "Bank",     format: 'none'  , sortable: true },
    { name: "account",  displayName: "Account",  format: 'none'  , sortable: true },
    { name: "details",  displayName: "",         format: 'button', sortable: false },
  ]

  const fetchData = async () => {
    const res1 = await authedGet("/customer/get-account-info");
    if (!res1.data["success"]) {
      setLoadingError(res1.data["message"]);
    } else {
      const data: Data[] = res1.data.account_info.map((acct: Raw) => {
        return {
          bank: acct.bank,
          account: acct.account,
          details: () => {
            setAcctDetails(acct);
            setDetails(true);
          }
        };
      });
      setData(data);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const detailsModal = () => {
    return (
      <GlassModal isOpen={details} onClose={() => setDetails(false)}>
        <h2>Account Validators</h2>
        <div>
          { acctDetails ? acctDetails.attestors.map((validator: string) => {
              return <div>{validator}</div>
            }) : <div>No validators</div>
          }
        </div>
        <div style={{height: 20}}/>
        <Button onClick={() => setDetails(false)} text="Close" width={-1}/>
      </GlassModal>
    );
  }

  return (
    <>
      { loading ? <div>Loading...</div> :
        (loadingError!==null) ? <div>Error: {loadingError}</div> :
        <>
            <Table columns={columns} data={data}/>
            {detailsModal()}
        </>
      }
    </>
  );
}

export default Accounts;
