import React, { useState}  from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Header.module.css';
import Button from './Button';

interface HeaderProps {
  pageName: string;
  isAuthed: () => boolean;
  logoImage: string;
}

const Header: React.FC<HeaderProps> = ({ pageName, isAuthed, logoImage }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const isAuthenticated = isAuthed();
  const navigator = useNavigate();

  const handleIconClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigator('/');
  };

  return (
    <header className={styles.header}>
      <div className={styles.leftSide}>
        <img src={logoImage} alt="Logo" className={styles.logo} />
        <div className={styles.separator}></div>
        <span className={styles.pageName}>{pageName}</span>
      </div>
      <div>
        {isAuthenticated ? (
          <div className={styles.iconContainer}>
            <div className={styles.icon} onClick={handleIconClick}>
              {/* Replace the placeholder with the actual person icon */}
              <span>👤</span>
            </div>
            {showDropdown && (
              <div className={styles.dropdown}>
                <button className={styles.dropdownItem}>Settings</button>
                <button className={styles.dropdownItem} onClick={handleLogout}>
                  Logout
                </button>
              </div>
            )}
          </div>
        ) : (
          <Button onClick={() => navigator('/login')} text="Login" />
        )}
      </div>
    </header>
  );
};

export default Header;