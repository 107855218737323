import TabbedContainer from '../components/TabbedContainer';
import Header from '../components/Header'

import Users from './Users';
import Customers from './Customers';
import Validators from './Validators';
import Relationships from './Relationships';
import Banks from './Banks';
import Accounts from './Accounts';
import Validations from './Validations';
import Proofs from './Proofs';
import BankData from './BankData';
import { isAuthed } from '../api';

import logoImage from '../images/proven-logo-red.png';

import styles from './Admin.module.css';

function Admin() {
    return (
        <>
        <Header pageName={'Customer'} isAuthed={isAuthed} logoImage={logoImage}/>
          <TabbedContainer tabs={[
            { title: "Users"       , content: <Users />     },
            { title: "Customers"   , content: <Customers /> },
            { title: "Validators & Links",
              content:
                <div className={styles.boxcontainer}>
                  <div className={styles.leftbox}><Validators /></div>
                  <div className={styles.rightbox}><Relationships /></div>
                </div>
            },
            {
              title: "Banks & Accounts",
              content:
                <div className={styles.boxcontainer}>
                  <div className={styles.leftbox}><Banks /></div>
                  <div className={styles.rightbox}><Accounts /></div>
                </div>
            },
            { title: "Validations", content: <Validations/> },
            { title: "Proofs"     , content: <Proofs/>      },
            { title: "Bank Data"  , content: <BankData/>    },
          ]}/>
        </>
    );
}

export default Admin;
