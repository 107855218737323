import React, { InputHTMLAttributes } from 'react';
import styles from './Input.module.css';

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  isPassword?: boolean;
}

const CustomInput: React.FC<CustomInputProps> = ({ isPassword, ...props }) => {
  return (
    <input
      {...props}
      className={styles.Input}
      type={isPassword ? 'password' : 'text'}
    />
  );
};

export default CustomInput;
