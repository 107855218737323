import { useState, useEffect } from 'react';
import Select from 'react-select';
import Button, { ButtonVariant } from '../components/Button';
import GlassModal from "../components/GlassModal";
import { authedGet, authedPost, yyyymmddToDate } from '../api';

import styles from './Admin.module.css';
import { Validation } from './AdminTypes';
import ValidationTable, { VldRowData } from './ValidationTable';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

type Atts = Map<number, Validation>;
type Dates = Map<number, Atts>;
type Customers = Map<number, { name: string; dates: Dates }>;

type LoadingError = string | null;

const CreateProof: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<LoadingError>(null);

  const [data, setData] = useState<Customers>(new Map());

  const [customer, setRawCustomer] = useState<{ label: string; value: number } | null>(null);
  const [customerOptions, setCustomerOptions] = useState<{ label: string; value: number }[]>([]);

  const [date, setRawDate] = useState<{ label: string; value: number } | null>(null);
  const [dateOptions, setDateOptions] = useState<{ label: string; value: number }[]>([]);

  const [vldRows, setVldRows] = useState<VldRowData[]>([]);
  const [vldSelectedRows, setVldSelectedRows] = useState<number[]>([]);

  const [minValidations, setRawMinValidations] = useState<{ label: string; value: number } | null>(null);

  const [createBtn, setCreateBtn] = useState<ButtonVariant>('disabled');

  const fetchData = async () => {
    const res1 = await authedGet("/admin/list-attestations");
    if (!res1.data["success"]) {
      setLoadingError(res1.data["message"]);
    } else {
      const customers: Customers = new Map();
      for (const vld of res1.data.attestations) {
        const date = vld.proof_id;
        if (!customers.has(vld.customer_id)) {
          const atts: Atts = new Map();
          atts.set(vld.id, vld);
          const dates: Dates = new Map();
          dates.set(date, atts);
          customers.set(vld.customer_id, { name: vld.customer, dates: dates });
        } else {
          if (!customers.get(vld.customer_id)?.dates.has(date)) {
            const atts: Atts = new Map();
            atts.set(vld.id, vld);
            customers.get(vld.customer_id)?.dates.set(date, atts);
          } else {
            customers.get(vld.customer_id)?.dates.get(date)?.set(vld.id, vld);
          }
        }
      };
      setData(customers);
      const custOpts: { value: number; label: string; }[] = [];
      customers.forEach((value, key) => {
        custOpts.push({ value: key, label: value.name });
      });
      setCustomerOptions(custOpts);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  const setCustomer = (customer: { label: string; value: number } | null) => {
    setRawCustomer(customer);
    const dateOpts: { value: number; label: string; }[] = [];
    if (customer) {
      data.get(customer.value)?.dates.forEach((value, key) => {
        dateOpts.push({ value: key, label: yyyymmddToDate(key).toLocaleDateString() });
      });
    };
    setDateOptions(dateOpts);
  }

  const setDate = (date: { label: string; value: number } | null) => {
    setRawDate(date);
    const attRows: VldRowData[] = [];
    if (date) {
      data.get(customer?.value as number)?.dates.get(date.value)?.forEach((value, key) => {
        attRows.push({
          id: key,
          bank: value.bank,
          account: value.account,
          validator: value.attestor,
          balance: value.balance,
        });
      });
    };
    setVldRows(attRows);
  }

  const onClose = () => {
    setCustomer(null);
    setCustomerOptions([]);
    setDate(null);
    setDateOptions([]);
    setVldRows([]);
    setVldSelectedRows([]);
    setCreateBtn('disabled');
    setIsOpen(false);
  }

  const handleCreate = () => {
    const vldIds = vldSelectedRows.map(id => vldRows.find(row => row.id === id)?.id as number);
    const body = {
      customer_id: customer?.value as number,
      proof_id: date?.value as number,
      attestation_ids: vldIds,
      min_attestations: minValidations?.value
    };
    console.log(body);
    authedPost("/admin/run-proof", body)
      .then(res => {
        if (!res.data["success"]) {
          alert(res.data["msg"]);
        } else {
          onClose();
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err);
          alert(err.response.data["msg"]);
        }
      });
    }

  const onAttSelChange = (selectedRows: VldRowData[]) => {
    const ids = selectedRows.map(row => row.id);
    setVldSelectedRows(ids);
  }

  const setMinValidations = (minValidations: { label: string; value: number } | null) => {
    setRawMinValidations(minValidations);
    if (minValidations !== null) {
      if (minValidations.value > 0) {
        setCreateBtn('default');
      } else {
        setCreateBtn('disabled');
      }
    }
  }

  const minAttOptions = [1].map(n => ({ label: n.toString(), value: n }));

  return (
    <GlassModal isOpen={isOpen} onClose={onClose} width="600px">
      { loading ? <div>Loading...</div> :
        (loadingError!==null) ? <div>Error: {loadingError}</div> :
        <>
          <h2>Validations</h2>
          <div>
          <table className={styles.table}>
            <tbody>
              <tr className={styles.row}>
                <td className={styles['field-name']}>Customer</td>
                <td>
                  <Select value={customer} onChange={setCustomer} options={customerOptions} className={styles['input-box']}/>
                </td>
              </tr>
              { customer && (
                <tr className={styles.row}>
                  <td className={styles['field-name']}>Date</td>
                  <td>
                    <Select value={date} onChange={setDate} options={dateOptions} className={styles['input-box']}/>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          </div>
          { date && <ValidationTable rows={vldRows} onSelectionChange={onAttSelChange}/> }
          { vldSelectedRows.length > 0 &&
            <table className={styles.table}>
              <tbody>
                <tr className={styles.row}>
                  <td className={styles['field-name']}>Min Validations Per Account</td>
                  <td>
                    <Select value={minValidations} onChange={setMinValidations} options={minAttOptions} className={styles['input-box']}/>
                  </td>
                </tr>
              </tbody>
            </table>
          }
          <Button onClick={handleCreate} text="Create Proof" width={-1} variant={createBtn} style={{marginTop: 20}}/>
        </>
      }
    </GlassModal>
  );
}

export default CreateProof;
