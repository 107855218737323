import { useState, useEffect } from 'react';
import Button from '../components/Button';
import Table, { Column } from '../components/Table';
import GlassModal from "../components/GlassModal";
import { authedGet, authedPost } from '../api';

import styles from './Admin.module.css';

interface Link {
  id: number;
  attestor_id: number;
  attestor: string;
  account_id: number;
  customer: string;
  bank: string;
  account: string;
};

interface Data {
  id: number;
  attestor_id: number;
  attestor: string;
  account_id: number;
  customer: string;
  bank: string;
  account: string;
  remove: React.FC;
};

type LoadingError = string | null;

function Relationships() {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<LoadingError>(null);

  const [data, setData] = useState<Data[]>([]);
  const [details, setDetails] = useState(false);
  const [newValidatorID, setNewValidatorID] = useState("");
  const [newAccountID, setNewAccountID] = useState("");

  const columns : Column[] = [
    { name: "id"         , displayName: "Link ID"  , format: 'none'  , sortable: true },
    { name: "attestor_id", displayName: "Vld ID"   , format: 'none'  , sortable: true },
    { name: "attestor"   , displayName: "Validator", format: 'none'  , sortable: true },
    { name: "account_id" , displayName: "Acct ID"  , format: 'none'  , sortable: true },
    { name: "customer"   , displayName: "Customer" , format: 'none'  , sortable: true },
    { name: "bank"       , displayName: "Bank"     , format: 'none'  , sortable: true },
    { name: "account"    , displayName: "Account"  , format: 'none'  , sortable: true },
    { name: "remove"     , displayName: "Remove"   , format: 'widget', sortable: false}
  ]

  const fetchData = async () => {
    const res1 = await authedGet("/admin/list-attestor-account-links");
    if (!res1.data["success"]) {
      setLoadingError(res1.data["message"]);
    } else {
      const localData: Data[] = res1.data.attestor_account_links.map((link: Link) => {
        const execute = async () => {
          const body = {
            id: link.id
          };
          authedPost("/admin/remove-attestor-account-link", body)
            .then(res => {
              if (!res.data["success"]) {
                alert(res.data["msg"]);
              } else {
                fetchData();
              }
            })
            .catch(err => {
              if (err.response) {
                console.log(err);
                alert(err.response.data["msg"]);
              }
            });
        };
        return {...link,
          remove: <Button onClick={execute} text="Remove" width={-1}/>
        };
      });
      setData(localData);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const addModal = () => {
    const handleAdd = async () => {
      const body = {
        attestor_id: newValidatorID,
        account_id: newAccountID
      };
      authedPost("/admin/add-attestor-account-link", body)
        .then(res => {
          if (!res.data["success"]) {
            alert(res.data["msg"]);
          } else {
            setDetails(false);
            fetchData();
          }
        })
        .catch(err => {
          if (err.response) {
            console.log(err);
            alert(err.response.data["msg"]);
          }
        });
    };
    return (
      <GlassModal isOpen={details} onClose={() => setDetails(false)}>
        <h2>Add Validator To Account Link</h2>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Validator ID</td>
              <td>
                <input
                  type="number"
                  className={styles['input-box']}
                  value={newValidatorID}
                  onChange={(e: any) => setNewValidatorID(e.target.value)}
                />
              </td>
            </tr>
            <tr className={styles.row}>
              <td className={styles['field-name']}>Account ID</td>
              <td>
                <input
                  type="number"
                  className={styles['input-box']}
                  value={newAccountID}
                  onChange={(e: any) => setNewAccountID(e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{height: 20}}></div>
        <Button onClick={() => handleAdd()} text="Add To Database" width={-1}/>
      </GlassModal>
    );
  }

  return (
    <>
      { loading ? <div>Loading...</div> :
        (loadingError!==null) ? <div>Error: {loadingError}</div> :
        <>
          <div className="table_header">
            <div style={{marginRight: 'auto'}}>
                <h2 style={{marginTop: 0, marginBottom: '4px'}}>
                    Validator To Account Links
                </h2>
            </div>
            <div style={{marginLeft: 'auto'}}>
                <Button onClick={() => setDetails(true)} text={'Add Link'} variant='alternative' width={150}/>
            </div>
          </div>

          <Table columns={columns} data={data}/>
          {addModal()}
        </>
      }
    </>
  );
}

export default Relationships;
